<template>
  <div v-if="personalisedReportResource">
    <PersonalisedReportForm
      :formMode="formMode"
      :personalisedReportResource="personalisedReportResource"
    ></PersonalisedReportForm>
  </div>
</template>

<script>
import PersonalisedReportForm from '../Form/PersonalisedReportForm.vue';
import { FORM_MODES } from '@/core/constants';
import { PersonalizedReportService } from '@/services';
import { ResetHeadingTitleMixins } from '@/core/mixins';

export default {
  name: 'LimsPersonalisedReportEdit',
  mixins: [ResetHeadingTitleMixins],
  components: {
    PersonalisedReportForm,
  },
  props: {
    personalisedReportId: null,
  },
  data() {
    return {
      personalisedReportResource: null,
    };
  },
  created() {
    this.getPersonalReport();
  },
  computed: {
    formMode() {
      return FORM_MODES.EDIT;
    },
  },
  methods: {
    async getPersonalReport() {
      const { data, error } = await PersonalizedReportService.getPersonalizedReport(this.personalisedReportId);
      if (error) {
        this.$alertErrorServerSide(error);
      } else {
        if (data) {
          this.personalisedReportResource = data;
        }
      }
    },
  },
};
</script>

<style></style>
